<template>
  <div class="orange-lianer">
    <img src="@/assets/img/icon995.png" class="absolute left0 top0 w100" />
    <div class="container absolute w90">
      <img src="@/assets/img/icon999.png" class="absolute w100 icon999" />
      <img src="@/assets/img/icon996.png" class="absolute w60 icon996" />
      <div class="bgf absolute left0 top0 w100 h100 wrappend">
        <div class="p30 register">
          <van-form @submit="onSubmit">
            <div class="bb row vertical">
              <span class="font18 c3">+86</span>
              <van-field
                v-model="form.phone"
                name="phone"
                label=""
                placeholder="请输入您的手机号码"
                :rules="[]"
              />
            </div>
            <div class="bb row vertical">
              <van-field
                v-model="form.smsCode"
                name="smsCode"
                label=""
                placeholder="请输入验证码"
                :rules="[]"
              />
              <div class="tc w170px" v-if="timerFlag">
                {{ timerNum }}秒后重新获取
              </div>
              <span class="w170px" @click="startSendMail" v-else>{{
                getMailMsg
              }}</span>
            </div>
            <div class="mt20">
              <van-button block type="info" color="#FF740D" native-type="submit"
                >注册</van-button
              >
            </div>
            <div class="tc c9 font12 mt20">
              <span>注册即代表阅读并同意舒昕逸家</span>
              <a
                class="blue"
                @click="$router.push({ path: '/recommend/protocol' })"
                >《商家入驻协议》</a
              >
            </div>
          </van-form>
        </div>
      </div>
    </div>
    <!-- 错误提示框 -->
    <div
      class="fixed top0 left0 w100 h100 opacity-wrapper"
      v-show="hasError"
      @click="clearMsg(0)"
    >
      <div class="fixed tc py50 cf error">{{ msg }}</div>
    </div>
    <!-- 阿里滑动验证 -->
    <div v-show="sendMailFlag">
      <div class="slide-login absolute left0 top0 w100 h100">
        <div class="relative wrapper">
          <div class="py20 tc">
            <img src="@/assets/img/icon986.png" class="w50" />
          </div>
          <div
            id="aliValidate"
            class="aliValidate"
            @click="(e) => e.stopPropagation()"
          ></div>
          <div class="closeBtn" @click="sendMailFlag = false"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recommend",
  components: {},
  data() {
    return {
      form: {
        phone: "",
        smsCode: "",
      },
      hasError: false,
      msg: "",
      timer: null, //验证码定时器
      timerFlag: false, //是否显示验证码定时器
      timerNum: 60,
      sendMailFlag: false, //开始发送验证码
      getMailMsg: "获取验证码",
    };
  },
  methods: {
    validatePhone(val) {
      let reg = /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
      return reg.test(val);
    },
    showMsg(msg = "") {
      this.msg = msg;
      this.hasError = true;
      this.clearMsg();
    },
    clearMsg(time = 3000) {
      setTimeout(() => {
        this.hasError = false;
      }, time);
    },
    // 验证码定时器
    stopTimer() {
      this.timerNum = 60;
      this.getMailMsg = "重新获取验证码";
      this.timerFlag = false;
      this.sendMailFlag = false;
      clearInterval(this.timer);
    },
    startTimer() {
      this.timerFlag = true;
      this.timer = setInterval(() => {
        this.timerNum -= 1;
        if (this.timerNum <= 0) {
          this.timerFlag = false;
          this.timerNum = 60;
          this.getMailMsg = "重新获取验证码";
          clearInterval(this.timer);
        }
      }, 1000);
    },
    // 准备发送验证码
    startSendMail() {
      let phone = this.form.phone;
      if (phone == "") {
        this.showMsg("请输入手机号");
        return;
      }
      if (!this.validatePhone(phone)) {
        this.showMsg("请输入正确的手机号");
        return;
      }
      this.sendMailFlag = true;
    },
    // 阿里人机验证
    initAliValiadte() {
      let vue = this;
      // 实例化ic对象
      AWSC.use("ic", function(state, module) {
        // 初始化
        window.ic = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: "FFFF0N00000000009C35",
          // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: "ic_message_h5",
          // 声明智能验证需要渲染的目标元素ID。
          renderTo: "aliValidate",
          // 该配置项为测试项 在仅用来测试验证码不同状态时使用。上线时请将其删除. 智能验证test配置项有4种不同的值对应不同的验证码状态，具体请参考文中参数定义说明部分。
          // test: module.TEST_PASS, // 测试智能验证通过
          // test: module.TEST_BLOCK, // 测试智能验证失败
          // test: module.TEST_NC_PASS, // 唤醒滑动验证并验证通过
          // test: module.TEST_NC_BLOCK, // 唤醒滑动验证并验证失败
          // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: function(data) {
            vue.validateSendMail({
              sessionId: data.sessionId,
              sig: data.sig,
              token: data.token,
              scene: "ic_message_h5",
            });
            window.ic.reset();
          },
          // 验证失败时触发该回调参数
          fail: function(failCode) {
            console.log("ali验证失败", failCode);
            vue.showMsg("验证失败");
            window.ic.reset();
          },
          // 验证码加载异常时触发该回调参数
          error: function(errorCode) {
            console.log("ali组件错误", errorCode);
            vue.showMsg("组件加载失败");
            window.ic.reset();
          },
        });
      });
    },
    // 人机验证通过，去发送验证码
    validateSendMail(params) {
      console.log("params===", params);
      this.sendMailFlag = false;
      this.$axios({
        url: this.$requests.tech.getCodeV3,
        method: "post",
        data: {
          phone: this.form.phone,
          type: "REGISTER", //REGISTER：注册，LOGIN：登录，FIND_PASSWORD：找回密码
          // captchaVerification: params.captchaVerification,
          ...params,
        },
      })
        .then((res) => {
          if (res.data.code !== 200) {
            this.stopTimer();
            this.showMsg(res.data.message || "请求失败");
            return;
          }
          this.showMsg("短信已成功发送，请耐心等待");
        })
        .catch((err) => {
          console.log("err===", err);
          this.stopTimer();
          this.showMsg("请求错误");
        });
      this.startTimer();
    },
    onSubmit() {
      if (this.form.phone == "") {
        this.showMsg("请输入手机号");
        return;
      }
      if (this.form.smsCode == "") {
        this.showMsg("请输入验证码");
        return;
      }
      this.$axios({
        url: this.$requests.tech.register,
        method: "post",
        data: {
          phone: this.form.phone,
          smsCode: this.form.smsCode,
          inviteCode: sessionStorage.getItem("inviteCode"),
          // inviteCode: "88064062608515072",
        },
      })
        .then((res) => {
          if (res.data.code !== 200) {
            this.stopTimer();
            this.showMsg(res.data.message || "请求失败");
            return;
          }
          this.$router.push({
            path: "/recommend/download",
          });
        })
        .catch((err) => {
          console.log("err===", err);
          this.stopTimer();
          this.showMsg("请求错误");
        });
    },
    record() {
      this.$axios({
        url: this.$requests.tech.generateRecord,
        method: "post",
      });
    },
  },
  mounted() {
    this.record();
    this.initAliValiadte();

    let { code } = this.$route.query;
    if (code) {
      sessionStorage.setItem("inviteCode", code);
    } else {
      sessionStorage.setItem("inviteCode", "88064062608515072");
    }
  },
};
</script>

<style lang="less" scoped>
.orange-lianer {
  height: 100%;
  background: linear-gradient(125deg, #fe562b, #ff9500);
  .container {
    left: 5%;
    bottom: 20px;
    height: 50%;
    .icon999 {
      top: -275px;
    }
    .icon996 {
      top: -110px;
      z-index: 3;
      left: 20%;
    }
    .wrappend {
      z-index: 2;
      border-radius: 10px;
      .register {
        position: absolute;
        top: 50%;
        height: 260px;
        margin-top: -130px;
      }
    }
  }
  .opacity-wrapper {
    z-index: 5;
    .error {
      top: 50%;
      left: 10%;
      margin-top: -50px;
      width: 80%;
      max-height: 200px;
      background: rgba(0, 0, 0, 0.6);
    }
  }
}
.slide-shadow {
  z-index: 2000;
  background: rgba(0, 0, 0, 0.2);
}
// ali验证样式修改
/deep/.sm-btn-wrapper {
  background: #fff;
}
.index {
  background: rgba(0, 0, 0, 0.2);
}
.aliValidate {
  height: 50px;
}
.slide-login {
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
}
.closeBtn {
  position: absolute;
  left: 50%;
  bottom: -60px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  background: url(../../assets/img/close.png) no-repeat center;
  background-size: 100% 100%;
}
</style>
